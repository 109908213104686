.swiper--custom_overflow {
  .swiper {
    overflow: scroll !important;
    &::-webkit-scrollbar {
      display: none;
    }
    &::-webkit-scrollbar-track {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      display: none;
    }
    scrollbar-width: none;
  }
}
