@mixin desktop-1366 {
  @media screen and (max-width: 1367px) {
    @content;
  }
}

@mixin desktop-1023 {
  @media screen and (max-width: 1023px) {
    @content;
  }
}

@mixin desktop-1024 {
  @media screen and (max-width: 1025px) {
    @content;
  }
}

@mixin desktop-1620 {
  @media screen and (max-width: 1621px) {
    @content;
  }
}

@mixin desktop-1520 {
  @media only screen and (min-width: 1440px) and (max-width: 1839px) {
    @content;
  }
}

@mixin desktop-1750 {
  @media screen and (max-width: 1751px) {
    @content;
  }
}

@mixin mobile-320 {
  @media screen and (max-width: 321px) {
    @content;
  }
}

@mixin mobile-388 {
  @media screen and (max-width: 388px) {
    @content;
  }
}

@mixin mobile-min-max-375 {
  @media screen and (min-width: 321px) and (max-width: 374px) {
    @content;
  }
}

@mixin mobile-min-427 {
  @media screen and (min-width: 427px) {
    @content;
  }
}

@mixin mobile-min-450 {
  @media screen and (min-width: 450px) {
    @content;
  }
}

@mixin mobile-min-500 {
  @media screen and (min-width: 500px) {
    @content;
  }
}

@mixin mobile-400 {
  @media screen and (max-width: 388px) {
    @content;
  }
}

@mixin mobile-height-568 {
  @media screen and (max-height: 569px) {
    @content;
  }
}

@mixin mobile-height-628 {
  @media screen and (max-height: 629px) {
    @content;
  }
}

@mixin mobile-height-690 {
  @media screen and (max-height: 691px) {
    @content;
  }
}

@mixin mobile-height-custom($height) {
  @media screen and (max-height: $height) {
    @content;
  }
}

@mixin mobile-width-custom($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin desktop-height-768 {
  @media screen and (max-height: 769px) {
    @content;
  }
}

@mixin desktop-1090 {
  @media screen and (max-width: 1090px) {
    @content;
  }
}

@mixin desktop-1024-to-1365 {
  @media screen and (min-width: 1024px) and (max-width: 1365px) {
    @content;
  }
}

@mixin desktop-1024 {
  @media screen and (min-width: 1024px) and (max-width: 1124px) {
    @content;
  }
}

@mixin desktop-1124 {
  @media screen and (min-width: 1124px) and (max-width: 1224px) {
    @content;
  }
}

@mixin desktop-1224 {
  @media screen and (min-width: 1224px) and (max-width: 1365px) {
    @content;
  }
}

@mixin desktop-1365 {
  @media screen and (min-width: 1335px) and (max-width: 1800px) {
    @content;
  }
}

@mixin mobile-landscape {
  @media screen and (orientation: landscape) {
    @content;
  }
}

@mixin mobile-landscape-height {
  @media screen and (orientation: landscape) and (max-height: 650px) {
    @content;
  }
}

$z-index: (
  max: 2000,
  level5: 1000,
  level4: 500,
  level3: 100,
  level2: 2,
  level1: 1,
  negative: -1
);

@function z-index($key) {
  @return map-get($z-index, $key);
}

@mixin z-index($key) {
  z-index: z-index($key);
}

@mixin flex($justify-content, $align-items) {
  @include flex-align($justify-content, $align-items);

  display: flex;
}

@mixin flex-column() {
  display: flex;
  flex-direction: column;
}

@mixin flex-align($justify-content, $align-items) {
  align-items: $align-items;
  justify-content: $justify-content;
}

@mixin scrollbar($width, $margin, $border-radius, $thumb-bg, $track-bg) {
  @supports not selector(::-webkit-scrollbar) {
    scrollbar-width: thin;
    scrollbar-color: $thumb-bg transparent;
  }
  // For Google Chrome
  ::-webkit-scrollbar,
  &::-webkit-scrollbar {
    width: $width;
    margin: $margin;
  }

  ::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb {
    border-radius: $border-radius;
    background: $thumb-bg;
  }

  ::-webkit-scrollbar-track,
  &::-webkit-scrollbar-track {
    background: $track-bg;
  }
}

@mixin counter-size($size, $height: true) {
  @if $height {
    @media only screen and (max-width: 1567px) {
      height: $size * 0.8;
    }

    @media only screen and (max-width: 1367px) {
      height: $size * 0.7;
    }

    @media only screen and (min-width: 1568px) {
      height: $size;
    }
  } @else {
    @media only screen and (max-width: 1567px) {
      width: $size * 0.8;
    }

    @media only screen and (max-width: 1367px) {
      width: $size * 0.7;
    }

    @media only screen and (min-width: 1568px) {
      width: $size;
    }
  }
}

@mixin position($size, $property) {
  @media only screen and (max-width: 1567px) {
    #{$property}: $size * 0.8;
  }

  @media only screen and (max-width: 1367px) {
    #{$property}: $size * 0.7;
  }

  @media only screen and (min-width: 1568px) {
    #{$property}: $size;
  }
}

@mixin shakeAnimation() {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
}

@mixin selectInputPlaceHolder() {
  position: relative;
  top: -48px;
  left: 10px;
  color: #7f8894;
  font-size: 11px;
  font-weight: lighter;
  white-space: nowrap;
  @include desktop-1024-to-1365() {
    font-size: 0.9vw;
  }
}

@mixin skeleton($display: block, $bkColor: rgba(255, 255, 255, 0.2)) {
  display: $display;
  background-color: $bkColor;
}

@mixin skeletonTemplate(
  $width,
  $height,
  $borderRadius,
  $margin,
  $bkColor: #001e28
) {
  width: $width;
  height: $height;
  border-radius: $borderRadius;
  margin: $margin;
  background-color: $bkColor;
}
