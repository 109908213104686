.payments {
  .swiper-scrollbar-drag {
    margin-left: 20px !important;
  }

  .swiper-slide {
    padding: 13px 0 17px !important;

    &:first-child {
      padding-left: 20px;
    }
  }

  .swiper-wrapper {
    margin: 0;
  }
}
