$t-swiper-after-z-index: 1;
$t-swiper-before-bg-color: #1c222a;

.fixed-background2 {
  .swiper {
    height: 119px;
    margin-top: 9px;
    padding: 0 9px;
    border-radius: 0;

    &::after {
      content: '';
      position: absolute;
      z-index: $t-swiper-after-z-index;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin-bottom: 8px;
      background-image: url('/assets/icons/top-matches/background.webp');
      background-repeat: no-repeat;
      background-size: cover;
    }

    .swiper-wrapper {
      position: relative;
      z-index: 2;
    }

    .swiper-slide {
      position: relative;
      padding: 0 !important;
      background: transparent;
    }
  }
}
