// common variables for workspace

// font
$font-medium: 'NeueCrocoSansMedium', sans-serif;
$font-regular: 'NeueCrocoSansRegular', sans-serif;

$font-prom-neue-croco-sans-caps: 'NeueCrocoSansCaps', sans-serif;
$font-prom-croco-sans-caps: 'CrocoSansCaps', sans-serif;
$font-prom-croco-sans-black-caps: 'CrocoSansBlackCaps', sans-serif;
$font-prom-croco-sans-regular: 'CrocoSansRegular', sans-serif;
$font-prom-goderdziani: 'Goderdziani', sans-serif;
$font-prom-super-molot: 'TTSupermolotNeue-ExtraBold', sans-serif;

// border-radius
$border-radius-sm: 4px;
$border-radius: 6px;
$border-radius-lg: 8px;

//loader-width
$loader-width: 67px;
$loader-width-web: 120px;

// border-color
$b-primary: #2f6475;

// font-sizes
$font-size-xxl: 20px;
$font-size-xl: 18px;
$font-size-lg: 16px;
$font-size-md: 14px;
$font-size-mdsm: 13px;
$font-size-sm: 12px;
$font-size-xs: 10px;

// colors
$color-white: #fff;
$color-black: #000;
$color-red: #ff2929;
$scroll-color: #1d4552;

// web specific variables

// colors
$primary: #1a1e23;
$secondary: #252b33;
$secondary-2: #575f6b;
$secondary-3: #414c57;
$secondary-4: #1f252d;
$secondary-active: #96a5b8;
$color-dark: #1d2631;
$color-grey: #d9e3ef;
$color-green: #189541;
$color-dark-blue: #2b333d;
$color-blue: #0e5276;
$color-blue-light: #1e77a6;
$light-skeleton-color: #093948;

// mobile specific variables

// colors
$m-primary: #001e28;
$m-primary-dark: #00141e;
$m-secondary: #0f2d37;
$m-secondary-light: #1f4956;
$m-green: #1caf4b;
$m-yellow: #c0b22e;
