$white-color: #fff;
$date-picker-border: 1px solid rgb(47 100 117 / 50%);
$background: #1e4754;
$btn-bg: #189541;
$date-picker-bg: #0f2d37;

.md-drppicker {
  border-radius: 12px !important;
  background: $date-picker-bg !important;
  text-align: center;

  &__wrapper {
    display: flex;
    position: relative;
    z-index: 1;
    align-items: center;
    justify-content: flex-start;
    border-radius: 26px;
    opacity: 0.8;
    cursor: pointer;
    padding: {
      top: 8px;
      bottom: 7px;
    }

    &--active {
      opacity: 1;
    }
  }

  .md-drppicker.shown {
    width: auto !important;
    background-color: $date-picker-bg;
  }
}

.md-drppicker .ranges {
  position: absolute;
  bottom: 23px;
  max-width: 351px !important;
}

.md-drppicker .ranges ul li:hover {
  background-color: transparent !important;
}

.table-condensed thead tr {
  display: flex;
  justify-content: space-between;
}

.md-drppicker .ranges ul li button {
  padding: 5px 0 0 28px !important;
  opacity: 0.5;
  color: $white-color;
}

.md-drppicker .calendar-table {
  border: transparent !important;
  background: transparent !important;
}

.md-drppicker .calendar td span,
.md-drppicker .calendar th span {
  color: #ccc;
}

.md-drppicker td.off,
.md-drppicker td.off.end-date,
.md-drppicker td.off.in-range,
.md-drppicker td.off.start-date {
  background: $background !important;
}

.calendar.right {
  display: none !important;
}

.calendar.left {
  max-width: 100% !important;
}

.md-drppicker .calendar td span,
.md-drppicker .calendar th span {
  line-height: 28px;
}

.md-drppicker td.active,
.md-drppicker td.active:hover {
  background: $background !important;
}

.md-drppicker .btn,
.md-drppicker .btn:focus,
.md-drppicker .btn:hover {
  min-width: 145px;
  border-radius: 26px !important;
  background: $btn-bg !important;
}

.md-drppicker .btn {
  margin-top: 15px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 4.4px 6px !important;
}

.md-drppicker.double {
  top: 52px !important;
  left: 0 !important;
  width: 100% !important;
  transform: translate(-50%m -50%);
}

.md-drppicker__input {
  max-width: 165px;
  padding-left: 10px;
  border: none;
  outline: none;
  background: transparent;
  color: rgb(255 255 255 / 80%);
  font-size: 12px;
  focus: none;
}

.md-drppicker .ranges ul li button.active {
  opacity: 1;
  background: transparent !important;
}

.md-drppicker td,
.md-drppicker th {
  width: 3em !important;
  height: 3em !important;
}

.md-drppicker.shown .calendar {
  max-width: 351px !important;
  margin: auto;
}

.md-drppicker .calendar td,
.md-drppicker .calendar th {
  display: inline-block;
  width: 40px !important;
  height: 40px !important;
  line-height: 40px;
}

.md-drppicker td {
  margin: 2px !important;
  opacity: 1 !important;
}

.md-drppicker td.in-range {
  border-radius: 50% !important;
  background: $background !important;
  color: $white-color !important;
}

.md-drppicker td.off,
.md-drppicker td.off.end-date,
.md-drppicker td.off.in-range,
.md-drppicker td.off.start-date {
  opacity: 0.3 !important;
  background-color: transparent !important;
}

.md-drppicker td.start-date.end-date {
  border-radius: 50%;
}

.md-drppicker td.start-date {
  border-radius: 50% !important;
}

.md-drppicker td.end-date {
  border-radius: 50% !important;
}

.off {
  opacity: 0.3 !important;
}

.md-drppicker th.month {
  width: auto !important;
  color: $white-color !important;
  font-family: NeueCrocoSansMedium, sans-serif;
  font-size: 16px !important;
  font-weight: 500;
}

.md-drppicker .calendar td span,
.md-drppicker .calendar th span {
  color: $white-color !important;
  font-family: NeueCrocoSansRegular, sans-serif;
  font-size: 12px !important;
  font-weight: 500;
}

.md-drppicker td.available.prev,
.md-drppicker th.available.prev {
  background-image: url('/assets/icons/shapes/calendar-arrow.svg') !important;
}

.md-drppicker td.available.next,
.md-drppicker th.available.next {
  transform: rotate(180deg);
  background-image: url('/assets/icons/shapes/calendar-arrow.svg') !important;
}

.md-drppicker td.available:hover,
.md-drppicker th.available:hover {
  background-color: $background !important;
}

.prev.available {
  background-color: transparent !important;
}

.next.available {
  background-color: transparent !important;
}

.md-drppicker__wrapper {
  padding-top: 0;
  padding-bottom: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
}

.input-wrapper {
  display: flex;
  border: $date-picker-border;
  border-radius: 26px;
  background-color: $date-picker-bg;
}

.md-drppicker__wrapper:focus {
  outline: none !important;
}

.md-drppicker__wrapper img,
.md-drppicker__wrapper input {
  padding: 8px 0 !important;
}

.md-drppicker__wrapper input {
  pointer-events: initial;
}

.md-drppicker__wrapper img {
  height: 32px;
  padding-left: 20px !important;
  border-top-left-radius: 26px;
  border-bottom-left-radius: 26px;
}

.md-drppicker__wrapper input {
  padding-left: 12px !important;
  border-top-right-radius: 26px;
  border-bottom-right-radius: 26px;
}

.drp-animate tr {
  display: flex;
  justify-content: space-between;
}

.calendar-table .next.available:hover {
  background-color: transparent !important;
}

.calendar-table .prev.available:hover {
  background-color: transparent !important;
}
@media (max-device-width: 350px) {
  .md-drppicker .calendar td,
  .md-drppicker .calendar th {
    width: 35px !important;
    height: 35px !important;
    line-height: 35px;
  }

  .md-drppicker th.month {
    width: 111px !important;
  }

  .md-drppicker .btn,
  .md-drppicker .btn:focus,
  .md-drppicker .btn:hover {
    min-width: 110px;
  }

  .md-drppicker .btn {
    margin-right: 10px !important;
    font-size: 12px !important;
    line-height: 25px !important;
  }

  .md-drppicker .ranges ul li button {
    padding: 3px 0 0 16px !important;
  }
}
