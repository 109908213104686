@use 'variables';

$font-path: '/assets/fonts/';
$promotions-font-path: '/assets/promotion-fonts/';

@mixin register-font($name) {
  @font-face {
    font-family: $name;
    src: url($font-path + $name + '.woff') format('woff'),
      url($font-path + $name + '.ttf') format('ttf');
    font-display: swap;
  }
}

@mixin register-promotion-font($name) {
  @font-face {
    font-family: $name;
    src: url($promotions-font-path + $name + '.woff') format('woff');
    font-display: swap;
  }
}

@include register-font(NeueCrocoSansMedium);
@include register-font(NeueCrocoSansRegular);

@include register-promotion-font(CrocoSansCaps);
@include register-promotion-font(CrocoSansRegular);
@include register-promotion-font(CrocoSansBlackCaps);
@include register-promotion-font(Goderdziani);
@include register-promotion-font(NeueCrocoSansCaps);
@include register-promotion-font(TTSupermolotNeue-ExtraBold);

* {
  font-family: variables.$font-medium;
}
