.background-transparent {
  .swiper {
    background-color: transparent;
  }

  .swiper-with-header .header {
    padding-top: 0;
    padding-left: 0;
  }

  .swiper-slide {
    background-color: transparent;
  }
}
