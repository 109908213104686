@use 'swiper';
@use 'ui';
@use 'datepicker2';
@use 'variables';
@use 'mixins';

* {
  touch-action: manipulation;
}

html {
  background-color: variables.$m-primary-dark;
}

body {
  max-width: 625px;
  margin: 0 auto;
  padding-bottom: 64px;
  background-color: variables.$m-primary-dark;
}

.no-scroll,
.noscroll {
  position: fixed;
  height: 100%;
  overflow: hidden;
  user-select: none;
  touch-action: none;
  overflow-block: hidden;
  @media screen and (min-width: 625px) {
    left: calc(50vw - (625px / 2));
  }
}

.no-padding-bottom {
  padding-bottom: 0;
}

.lc-frame-launcher {
  display: none !important;
}

.lc-frame-widget {
  left: 0 !important;
  width: 100% !important;
  max-width: 625px !important;
  min-height: 100% !important;
  margin: auto !important;
}

#fasttrack-crm .inbox {
  height: auto !important;
}

#fasttrack-crm .inbox {
  background-color: transparent !important;
}

#fasttrack-crm .selected-message .title {
  margin: 0 !important;
  align-items: center !important;
  justify-content: space-between !important;
  display: flex !important;
  height: 54px !important;
  padding: 0 20px 0 30px !important;
  border-bottom: 1px solid #00141e !important;
  font-size: variables.$font-size-md !important;
  color: variables.$color-white !important;
  font-weight: normal !important;
  position: absolute;
  top: 0;
}

#fasttrack-crm .selected-message .image img.loaded {
  margin-top: 50px;
  padding: 20px;
}

#fasttrack-crm .selected-message {
  background-color: #001e28 !important;
  border-radius: 24px !important;
  border: 1px solid #1f4956 !important;
  overflow: hidden;
}

#fasttrack-crm .selected-message .image {
  background-color: #001e28 !important;
}

#fasttrack-crm .close-btn {
  top: 10px !important;
  right: 17px !important;
  margin-right: 0 !important;
  margin-top: 0 !important;
  background-color: transparent !important;
  border: 1px solid #093948 !important;
  box-shadow: none !important;
}

#fasttrack-crm .close-btn:after,
#fasttrack-crm .close-btn:before {
  background-color: #a3acb7 !important;
  top: 7px !important;
  left: 14px !important;
}

#fasttrack-crm .selected-message:not(:has(.image)) .scroll-area {
  margin-top: 70px !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

#fasttrack-crm .selected-message:not(:has(.image)) .message-body {
  text-align: center !important;
  word-break: break-word;
  font-size: 16px !important;
}

#fasttrack-crm
  .inbox:not(:has(.inbox-navigation-bar))
  .selected-message
  .title {
  background-color: #072c38 !important;
  width: 100% !important;
}

#fasttrack-crm .selected-message:not(:has(.image)) .title {
  width: calc(100% + 16px) !important;
}

#fasttrack-crm .selected-message .message-body {
  margin: 20px 30px 10px !important;
  font-size: variables.$font-size-md !important;
  color: variables.$color-white !important;
  font-family: variables.$font-prom-croco-sans-regular !important;
  margin-top: 0 !important;
  overflow: hidden;
}

#fasttrack-crm .selected-message .buttons-row {
  margin: 0 15px !important;
}

#fasttrack-crm .cta-button {
  font-family: variables.$font-medium !important;
  font-size: 12px !important;
  box-shadow: none !important;
  font-weight: normal !important;
  border-radius: 25px !important;
  background-color: #189541 !important;
  height: 48px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

#fasttrack-crm .cta-button.secondary {
  background-color: #0e5276 !important;
  color: variables.$color-white !important;
}

#fasttrack-crm .selected-message .scroll-area {
  max-height: 590px !important;
  margin-top: -2px;
}

#fasttrack-crm .delete-button svg g,
#fasttrack-crm .delete-button svg g path {
  fill: white !important;
  opacity: 0.8 !important;
}

// Inbox
#fasttrack-crm .inbox-navigation-bar {
  position: absolute !important;
  width: 100% !important;
  height: 55px !important;
  background-color: #072c38 !important;
  border-radius: 24px 24px 0 0 !important;
  border-left: 1px solid #093948 !important;
  border-top: 1px solid #093948 !important;
  border-right: 1px solid #093948 !important;
}

#fasttrack-crm .back-button {
  color: #a3acb7 !important;
  bottom: 2px;
}
#fasttrack-crm .back-button:after,
#fasttrack-crm .back-button:before {
  background-color: #a3acb7 !important;
}

#fasttrack-crm .inbox-navigation-bar .contact-details {
  display: none;
}

#fasttrack-crm .messages-list-wrapper {
  margin-top: 55px;
  background-color: #001e28 !important;
  border-radius: 0 0 24px 24px !important;
  border-left: 1px solid #093948 !important;
  border-bottom: 1px solid #093948 !important;
  border-right: 1px solid #093948 !important;
}

#fasttrack-crm h4.title,
#fasttrack-crm p.text,
#fasttrack-crm p.date {
  color: #fff !important;
  opacity: 0.8;
}

#fasttrack-crm h4.title {
  font-family: variables.$font-medium !important;
}

#fasttrack-crm p.text {
  font-family: variables.$font-prom-croco-sans-regular !important;
}

#fasttrack-crm .inbox-navigation-bar h1.title {
  color: #fff !important;
  flex: 1 !important;
  font-family: variables.$font-medium !important;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

#fasttrack-crm .no-messages {
  color: #fff !important;
  font-family: variables.$font-prom-croco-sans-regular !important;
  font-size: variables.$font-size-md !important;
  text-align: center !important;
  margin-top: 50px !important;
  background-color: #001e28 !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

#fasttrack-crm .no-messages svg {
  fill: white !important;
  opacity: 0.5 !important;
}

#fasttrack-crm .no-messages span {
  color: #fff !important;
  opacity: 0.5 !important;
}

//Small
#fasttrack-crm .notification-small {
  background-color: #001e28 !important;
  color: variables.$color-white !important;
  border-radius: 24px !important;
  border: 1px solid #1f4956 !important;
  padding: 0 0 15px 0 !important;
  display: flex;
  width: 95%;
  margin: 0 auto 10px;
}

#fasttrack-crm .notification-small > .image-wrapper {
  width: auto !important;
}
#fasttrack-crm .notification-small > .close-btn {
  width: 32px !important;
}

#fasttrack-crm .notification-small > div {
  width: 100% !important;
}

#fasttrack-crm .notification-small:not(:has(.image-wrapper)) > div > .preview {
  padding: 15px !important;
  height: initial;
  min-height: initial !important;
  margin-bottom: 21px !important;
}

#fasttrack-crm .notification-small .title {
  margin: 0 !important;
  align-items: center !important;
  justify-content: space-between !important;
  display: flex !important;
  height: 54px !important;
  padding: 0 20px 0 30px !important;
  border-bottom: 1px solid #00141e !important;
  font-size: variables.$font-size-md !important;
  color: variables.$color-white !important;
  background-color: #072c38 !important;
  font-weight: normal !important;
  position: absolute;
  width: 100%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;

  @include mixins.mobile-320 {
    padding: 0 13px 0 14px !important;
    font-size: variables.$font-size-sm !important;
  }
}

#fasttrack-crm .notification-small .preview {
  font-size: variables.$font-size-mdsm !important;
  color: variables.$color-white !important;
  font-family: variables.$font-prom-croco-sans-regular !important;
  margin-bottom: 50px !important;
  margin-top: 50px !important;
  position: relative !important;
  padding-left: 132px !important;
  top: 12px !important;
  min-height: 85px !important;

  @include mixins.mobile-320 {
    max-height: 85px;
    overflow-y: scroll;
    padding-left: 85px !important;
    margin-bottom: 32px !important;
    font-size: variables.$font-size-sm !important;
  }
}

#fasttrack-crm .notification-small .show-message-button {
  font-size: variables.$font-size-md !important;
  color: variables.$color-white !important;
  background-color: #189541 !important;
  font-family: variables.$font-prom-croco-sans-regular !important;
  font-weight: normal !important;
  border-radius: 25px !important;
  left: 26%;
  transform: translateX(-50%);
  display: inline-block !important;
  float: left;
  width: 45% !important;
  margin: auto !important;
  padding: 16px 7px !important;

  @include mixins.mobile-320 {
    font-size: variables.$font-size-sm !important;
    padding: 15px 13px !important;
  }
}

#fasttrack-crm .notification-small div.show-message-button {
  width: 96% !important;
  left: 0 !important;
  transform: translateX(0) !important;
  margin: 0 2% !important;
}

#fasttrack-crm .notification-small .show-message-button.secondary {
  background-color: #0e5276 !important;
  color: variables.$color-white !important;
  margin-left: 12px !important;

  @include mixins.mobile-width-custom(480) {
    width: 94% !important;
  }

  @include mixins.mobile-320 {
    margin-left: 7px !important;
  }
}

#fasttrack-crm .notification-small .image-wrapper img {
  width: 100px !important;
  height: 100px !important;
  border-radius: 8px !important;
  position: absolute;
  top: 65px !important;
  left: 15px !important;

  @include mixins.mobile-320 {
    width: 60px !important;
    height: 60px !important;
  }
}

#fasttrack-crm .small-notifications-wrapper {
  justify-content: center !important;
  top: 50% !important;
  transform: translateY(-50%);
  padding: 0 !important;
}

.notification__content {
  .notification__p {
    line-height: normal;

    * {
      font-family: variables.$font-regular;
    }
  }
}

.single-notification__desc {
  line-height: normal;

  * {
    font-family: variables.$font-regular;
  }
}

.payment-button-container iframe {
  border-radius: 148px !important;
}

$shine-color: rgba(#e8e8e8, 0.6);
$animation-duration: 1.6s;

@keyframes shine-lines {
  0% {
    background-position: 0;
  }
  100% {
    background-position: 1024px
  }
}

@mixin background-gradient {
  background-image: -webkit-linear-gradient(90deg, rgba(152, 165, 168, 1) 0px, $shine-color 40%, rgba(152, 165, 168, 1) 80%);
  background-image: linear-gradient(90deg, rgba(152, 165, 168, 1) 0px, $shine-color 40%, rgba(152, 165, 168, 1) 80%);
  background-size: 100%;
}
.payment-button-container {
  position: relative;
  &-skeleton {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(152, 165, 168, 1);
    height: 48px;
    border-radius: 148px;
    @include background-gradient();
    animation: shine-lines $animation-duration linear infinite;
  }
}
