.scrollable-slider2 {
  .swiper {
    padding: 0 4px 0 3px;
  }

  .swiper-with-header .header {
    height: 30px;
    margin-right: 4px;
    margin-left: 4px;
  }

  .swiper-scrollbar-drag {
    position: relative;
    top: -1px;
    border-bottom-right-radius: 10%;
    border-bottom-left-radius: 10%;
    opacity: 1;
    background: #3e869c;
  }

  .swiper-scrollbar {
    height: 1px;
    background: rgba(#2f6475, 0.3);
  }

  .swiper-horizontal > .swiper-scrollbar {
    bottom: 0;
  }

  .swiper-with-header .header__title {
    width: 100%;
  }

  .swiper-with-header:not(.swiper-grid) .swiper-slide {
    padding: 0 8px 8px;
  }
}

.bonus-arrow {
  .swiper-button-next::after,
  .swiper-button-prev::after {
    color: white;
    font-size: 16px;
    font-weight: 900;
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: 0;
    width: 94px;
    height: 96px;
    border-radius: 0;
    opacity: 1;
  }

  .swiper-button-prev {
    display: flex;
    left: 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 20px;
    background: linear-gradient(270deg, rgb(0 30 40 / 0%) 0%, #001e28 100%);
  }

  .swiper-button-next {
    display: flex;
    right: 0;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-right: 20px;
    background: linear-gradient(270deg, #001e28 0%, rgb(0 30 40 / 0%) 100%);
  }

  .swiper-button-disabled {
    display: none;
  }
}
