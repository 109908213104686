@import '~swiper/swiper.min.css';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';
@import 'swiper/scss/scrollbar';
@import 'variables';

.swiper {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  border-radius: $border-radius;
  background: $color-swiper-background;

  .grid {
    display: flex;
    box-sizing: border-box;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    margin: 0 120px;
    padding: 20px 0;
    gap: $grid-gap;
  }

  .grid__item {
    flex-basis: calc((100% - #{$grid-gap}) / 2);
    max-height: calc((100% - #{$grid-gap}) / 2);
  }

  .switcher {
    display: flex;
    position: absolute;
    z-index: 2;
    top: 30px;
    right: 30px;
    box-sizing: border-box;
    flex-wrap: wrap;
    width: 63px;
    cursor: pointer;
    gap: 3px;

    &:hover .switcher__item {
      background-color: rgba($color-switcher-background, 0.8);
    }
  }

  .switcher__item {
    box-sizing: border-box;
    width: 30px;
    height: 10px;
    border-radius: 2px;
    background-color: rgba($color-switcher-background, 0.3);
  }

  &-carousel {
    .swiper-wrapper {
      height: 100%;
    }

    &:not(.swiper-grid) .swiper-slide {
      box-sizing: border-box;
      padding: 0 8px 8px;
    }

    .header {
      display: flex;
      box-sizing: border-box;
      align-items: center;
      height: $header-height;
      padding-top: 8px;
      padding-left: 10px;
      font-size: 15px;
    }

    .header__title {
      color: #d9e3ef;
    }

    .swiper-button-next {
      right: 10px;
    }

    .swiper-button-prev {
      right: 40px;
      left: unset;
    }

    .swiper-button-next,
    .swiper-button-prev {
      z-index: 1;
      top: 10px;
      width: 20px;
      height: 20px;
      margin-top: 0;
      border-radius: 4px;
      opacity: 0.6;
      background-color: $color-navigation-header-background;
      color: $color-navigation;
      font-weight: bold;

      &::after {
        color: $color-white;
        font-size: 12px;
      }
    }
  }

  &-with-header {
    .grid {
      margin: 0;
      padding: 8px;
      gap: $grid-headed-gap;
    }

    .grid__item {
      flex-basis: calc((100% - #{$grid-headed-gap}) / 2);
      max-height: calc((100% - #{$grid-headed-gap}) / 2);
    }

    .swiper-wrapper {
      height: calc(100% - #{$header-height});
    }

    &:not(.swiper-grid) .swiper-slide {
      box-sizing: border-box;
      padding: 8px;
    }

    .header {
      display: flex;
      box-sizing: border-box;
      align-items: center;
      height: $header-height;
      padding-top: 8px;
      padding-left: 10px;
      font-size: 15px;
    }

    .header__title {
      color: #d9e3ef;
    }

    .swiper-button-next {
      right: 10px;
    }

    .swiper-button-prev {
      right: 40px;
      left: unset;
    }

    .swiper-button-next,
    .swiper-button-prev {
      z-index: 1;
      top: 10px;
      width: 20px;
      height: 20px;
      margin-top: 0;
      border-radius: 4px;
      opacity: 0.6;
      background-color: $color-navigation-header-background;
      color: $color-navigation;
      font-weight: bold;

      &::after {
        color: $color-white;
        font-size: 12px;
      }

      &:hover {
        background-color: $color-navigation-header-hover;
      }
    }
  }
}

.swiper-no-paging .swiper-pagination-bullets {
  display: none;
}

.swiper-no-navigation {
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
}

.swiper-wrapper {
  z-index: -1;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-swiper-background;
  font-size: 18px;
  text-align: center;

  &:hover {
    cursor: grab;
  }

  &:active {
    cursor: grabbing;
  }
}

.swiper-no-grab {
  .swiper-slide {
    &:hover {
      cursor: pointer;
    }

    &:active {
      cursor: pointer;
    }

    a:active {
      cursor: pointer;
    }
  }
}

.swiper-pagination-bullets {
  box-sizing: border-box;
  padding-right: 30px;
  text-align: right;
  cursor: default;

  .swiper-pagination-bullet {
    box-sizing: border-box;
    width: 20px;
    height: 45px;
    margin: 0 0 0 12px !important;
    padding: 20px 0;
    opacity: 1;
    background: unset;

    .bullet {
      display: block;
      width: 20px;
      height: 5px;
      border-radius: 2px;
      opacity: 0.6;
      background-color: $color-white;
    }
  }

  .swiper-pagination-bullet-active .bullet {
    background-color: $color-pagination-active;
  }
}

.swiper-button-next,
.swiper-button-prev {
  z-index: 1;
  top: 0;
  width: 120px;
  height: 100%;
  margin-top: 0;
  opacity: 0.3;
  color: $color-navigation;
  font-weight: bold;

  &:hover {
    opacity: 0.8;
    color: $color-navigation;
  }

  &::after {
    font-size: 30px;
  }
}

.no-border {
  border-radius: 0;
}
