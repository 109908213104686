$border-radius: 6px;
$grid-gap: 20px;
$grid-headed-gap: 8px;
$header-height: 32px;
$color-swiper-background: #252b33;
$color-switcher-background: #d9e3ef;
$color-white: white;
$color-pagination-active: #15af44;
$color-pagination-active2: #1CAF4B;
$color-navigation: #a3acb7;
$color-navigation-header-hover: #575f6b;
$color-navigation-header-background: #444a53;
