@use 'variables';

.pagination-bottom .swiper {
  border-radius: 8px;

  .swiper-slide {
    align-items: flex-start;
  }

  .swiper-pagination-bullets {
    position: absolute;
    bottom: 8%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    text-align: center;
    cursor: default;

    .swiper-pagination-bullet {
      box-sizing: border-box;
      width: 5px;
      height: 5px;
      margin: 0 4px !important;
      padding: 0;
      opacity: 1;
      background: unset;

      .bullet {
        display: block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        opacity: 0.7;
      }
    }

    .swiper-pagination-bullet-active {
      .bullet {
        transition: all 0.3s ease;
        border-radius: 5px;
        opacity: 1;
        background-color: variables.$color-pagination-active2;
      }
    }
  }
}
