@use 'reset';
@use 'variables';
@use 'mixins';
@use 'grid';
@use 'buttons';
@use 'forms';
@use 'fonts';
@use 'animations';
@use 'shared/label-tooltip-new-blue';
$scrollbar-color: #323743;

html {
  @include mixins.scrollbar(
    5px,
    inherit,
    inherit,
    $scrollbar-color,
    transparent
  );
  width: 100%;
  min-height: 100%;
}

body {
  background-color: variables.$primary;
  color: variables.$color-black;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  user-select: none;
  width: 100%;
  min-height: 100%;
}

.overflow-hidden {
  height: 100vh;
  overflow: hidden;
}

.text-center {
  text-align: center;
}
