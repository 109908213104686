.title-border {
  .swiper {
    height: 100%;
  }

  .swiper-with-header .header {
    height: 39px;
    border-bottom: 1px solid #3b434d;
  }

  .swiper-with-header .swiper-button-prev {
    right: 31px;
  }

  .swiper-with-header .swiper-button-next {
    right: 0;
  }
}
